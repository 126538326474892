import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import PublicIcon from "@material-ui/icons/Public";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSnackbar } from "../../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../../app/contexts/study.context";

const BottomControls = ({ errors, hasErrors, sequentialCohort }) => {
  const history = useHistory();
  const { id, planId } = useParams();
  const { showSnackbar } = useSnackbar();
  const {
    savePlan,
    saveLoading,
    seqCohorts,
    unsavedChange,
    validateSequentialCohort,
    validateSeasonalVariations,
    isSequential,
  } = useStudyPlan();

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    const handleDisabled = () => {
      const hasEmptyFields = (data) => {
        const fieldsToCheck = [
          "cohortName",
          "patients",
          "lagDays",
          "dtlDurationDays",
          "screeningDurationDays",
          "srcDurationDays",
        ];

        for (const field of fieldsToCheck) {
          if (
            data[field] === "" ||
            data[field] === null ||
            data[field] === undefined
          ) {
            return true;
          }
        }

        return false;
      };

      const isCohortEmpty = seqCohorts.some((cohort) => hasEmptyFields(cohort));

      if (sequentialCohort) {
        if (!saveLoading && hasErrors.length === 0 && !isCohortEmpty) {
          setIsDisabled(false);
        } else {
          setIsDisabled(true);
        }
      } else {
        setIsDisabled(!(!saveLoading && hasErrors.length === 0));
      }
    };

    handleDisabled();
  }, [seqCohorts, saveLoading, hasErrors, sequentialCohort]);

  const pushToNextPage = () => {
    const seqValid = isSequential
      ? validateSequentialCohort(history.location.pathname)
      : true;

    const seasonalValid = validateSeasonalVariations();
    if (
      (Object.keys(errors || {}).length !== 0 &&
        ["ALL", "NEXT2"].includes(errors.BUTTON_TYPE)) ||
      !seqValid ||
      !seasonalValid
    ) {
      showSnackbar(
        <ul>
          {Object.keys(errors || {}).map((key) => {
            if (key !== "BUTTON_TYPE") return <li>{errors[key]}</li>;
            return "";
          })}
          {!seqValid && <li>Sequential cohorts setup is not valid</li>}
          {!seasonalValid && (
            <li>Country enrollment seasonal variations are not valid</li>
          )}
        </ul>,
        "error",
      );
      return;
    }
    if (unsavedChange) {
      savePlan({
        isNew: false,
        redirect: () =>
          history.push(`/ctpo/study-plans/${id}/${planId}/review`),
      });
    } else {
      history.push(`/ctpo/study-plans/${id}/${planId}/review`);
    }
  };

  return (
    <div>
      <Container>
        <Button
          color="primary"
          onClick={() =>
            document.getElementById("country-tabs").scrollIntoView()
          }
        >
          <PublicIcon />
          View Different Country
        </Button>
      </Container>
      <Container>
        <Button
          onClick={() => history.push(`/ctpo/study-plans/${id}/${planId}`)}
          disabled={isDisabled}
        >
          Back
        </Button>
        <Button
          onClick={pushToNextPage}
          variant="contained"
          color="primary"
          disabled={isDisabled}
        >
          {saveLoading ? <CircularProgress size="1em" /> : "Next Step"}
        </Button>
      </Container>
    </div>
  );
};

export default BottomControls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1em;
  margin-top: 1em;
`;

import { IconButton, MenuItem, TextField } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import ClearIcon from "@material-ui/icons/Clear";
import MuiTableHead from "@material-ui/core/TableHead";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CustomDatePickerRange from "../../../app/components/custom.date.picker.range";

const opportunityStageOptions = [
  "Closed Won",
  "Closed Cancelled",
  "Written Confirmation of Award",
  "Proposal Sent",
  "Rebid Sent",
  "Closed Not Bidding",
  "On Hold by Client",
  "RFP In House",
  "RFI Sent",
  "WCA Rebid Sent",
  "Closed Lost",
  "RFI Requested",
  "Rebid In House",
  "WCA Cancelled",
  "Verbal Award",
  "WCA Rebid in House",
  "Verbal Award Rebid Sent",
  "Drafting Proposal",
  "Developing Project Scope",
];

// create a styled table cell with no horizontal padding
const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const TableSidesNotIncludedFilterHead = ({
  filters,
  setFilters,
  orderBy,
  order,
}) => {
  const classes = useStyles();
  const headCells = [
    {
      id: "Country",
      type: "text",
      disablePadding: false,
      label: "Country",
      align: "left",
    },
    {
      id: "Central / Local",
      type: "text",
      disablePadding: false,
      label: "Central / Local",
      align: "left",
    },
    {
      id: "Site Number - PI",
      type: "text",
      disablePadding: false,
      label: "Site Number - PI",
      align: "left",
    },
    {
      id: "Site Selected Date",
      type: "date",
      disablePadding: false,
      label: "Site Selected Date",
      align: "left",
    },
    {
      id: "Site Activated - Actual",
      type: "date",
      disablePadding: false,
      label: "Site Activated - Actual",
      align: "left",
    },
    {
      id: "Site Activated - Forecast",
      type: "date",
      disablePadding: false,
      label: "Site Activated - Forecast",
      align: "left",
    },
    {
      id: "Site Status",
      type: "text",
      disablePadding: false,
      label: "Site Status",
      align: "left",
    },
  ];

  const updateFilters = (id, value, type, startEnd) => {
    if ([type === "y_n" || type === "select"] && value === "all") {
      const filtersCopy = { ...filters };
      delete filtersCopy[id];
      return setFilters(filtersCopy);
    }

    if (type === "date") {
      let prevValue = filters[id] || {
        id: id,
        type: "date",
      };
      if (startEnd === "start") {
        prevValue = {
          ...prevValue,
          start: value,
        };
      } else if (startEnd === "end") {
        prevValue = {
          ...prevValue,
          end: value,
        };
      }
      return setFilters({
        ...filters,
        [id]: prevValue,
      });
    }

    setFilters({
      ...filters,
      [id]: {
        ...filters[id],
        value: value,
        type: type,
      },
    });
  };

  const renderInputField = (id, type) => {
    if (type === "text")
      return (
        <TextField
          value={filters[id]?.value || ""}
          onChange={(e) => updateFilters(id, e.target.value, type)}
          size="small"
          variant="outlined"
          InputLabelProps={{ classes: classes }}
          fullWidth
        />
      );

    if (type === "select") {
      return (
        <TextField
          select
          size="small"
          id={`${id}-select`}
          value={filters[id]?.value ?? "all"}
          onChange={(e) => updateFilters(id, e.target.value, type)}
          variant="outlined"
          fullWidth
        >
          <MenuItem
            key="all"
            value="all"
          >
            All
          </MenuItem>
          {opportunityStageOptions.map((option) => (
            <MenuItem
              key={option}
              value={option}
            >
              {option}
            </MenuItem>
          ))}
        </TextField>
      );
    }
    if (type === "date")
      return (
        <CustomDatePickerRange
          selected={filters[id]?.start}
          onChange={(dates) => {
            const [start, end] = dates;
            const startDate = start ? new Date(start) : null;
            const endDate = end ? new Date(end) : null;
            if (startDate && !isNaN(startDate.getTime())) {
              updateFilters(id, startDate.getTime(), type, "start");
            }

            if (endDate && !isNaN(endDate.getTime())) {
              updateFilters(id, endDate.getTime(), type, "end");
            }
          }}
          startDate={filters[id]?.start ? new Date(filters[id]?.start) : null}
          endDate={filters[id]?.end ? new Date(filters[id]?.end) : null}
          selectsRange
          showYearDropdown
          showMonthDropdown
          useShortMonthInDropdown
          dateFormat={"dd/MMM/yyyy"}
          small={true}
          value={
            filters[id]?.start && filters[id]?.end
              ? [new Date(filters[id]?.start), new Date(filters[id]?.end)]
              : [null, null]
          }
        />
      );
  };
  return (
    <MuiTableHead classes={classes}>
      <TableRow classes={classes}>
        <StyledTableCell>
          {Object.keys(filters).length > 0 && (
            <IconButton
              onClick={() => setFilters({})}
              variant="outlined"
              color="primary"
              size="small"
            >
              <ClearIcon />
            </IconButton>
          )}
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            style={
              ["enrollmentPlanLastUpdate", "soeLastUpdate"].includes(
                headCell.id,
              )
                ? { minWidth: "10rem" }
                : {}
            }
            key={headCell.id}
            align="left"
            padding="default"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {renderInputField(headCell.id, headCell.type)}
          </StyledTableCell>
        ))}
      </TableRow>
    </MuiTableHead>
  );
};

export default TableSidesNotIncludedFilterHead;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#e6e7e8",
    borderRadius: "10px",
  },
}));

import MuiTableRow from "@material-ui/core/TableRow";
import MuiTooltip from "@material-ui/core/Tooltip";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import moment from "moment";
import { withStyles } from "@material-ui/core";

// create a styled table cell with no horizontal padding
export const StyledTableCell = withStyles((theme) => ({
  root: {
    paddingLeft: "5px",
    paddingRight: "5px",

    "&:first-child": {
      paddingLeft: "10px",
    },
  },
}))(TableCell);

const SidesTableRow = (props) => {
  const {
    Country,
    "Central / Local": centralLocal,
    "Site Number - PI": siteNumber,
    "Site Selected Date": siteSelectedDate,
    "Site Activated - Actual": siteActivatedActual,
    "Site Activated - Forecast": siteActivatedForecast,
    "Site Status": siteStatus,
  } = props;

  const formatDate = (date) => {
    if (!date) return "";
    // format date to MM/DD/YYYY using moment
    return moment(date).format("DD/MMM/YYYY");
  };

  return (
    <MuiTableRow>
      <StyledTableCell align="left"></StyledTableCell>
      <StyledTableCell
        align="left"
        style={{ minWidth: "7rem" }}
      >
        <MuiTooltip
          title={
            <p style={{ fontSize: "14px" }}>
              {props.index === 0 ? `Total: ${props.projects.length}` : Country}
            </p>
          }
        >
          <span>
            {" "}
            {props.index === 0 ? `Total: ${props.projects.length}` : Country}
          </span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell
        align="left"
        style={{ minWidth: "7rem" }}
      >
        <MuiTooltip title={<p style={{ fontSize: "14px" }}>{centralLocal}</p>}>
          <span>{centralLocal}</span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell align="left">
        <MuiTooltip title={<p style={{ fontSize: "14px" }}>{siteNumber}</p>}>
          <span>{siteNumber}</span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell align="left">
        <MuiTooltip
          title={<p style={{ fontSize: "14px" }}>{siteSelectedDate}</p>}
        >
          <span>{formatDate(siteSelectedDate)}</span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell align="left">
        <MuiTooltip
          title={<p style={{ fontSize: "14px" }}>{siteActivatedActual}</p>}
        >
          <span>{formatDate(siteActivatedActual)}</span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell align="left">
        <MuiTooltip
          title={<p style={{ fontSize: "14px" }}>{siteActivatedForecast}</p>}
        >
          <span>{formatDate(siteActivatedForecast)}</span>
        </MuiTooltip>
      </StyledTableCell>
      <StyledTableCell align="left">
        <MuiTooltip title={<p style={{ fontSize: "14px" }}>{siteStatus}</p>}>
          <span>{siteStatus}</span>
        </MuiTooltip>
      </StyledTableCell>
    </MuiTableRow>
  );
};

export default SidesTableRow;

import { useHistory, useParams } from "react-router-dom";

import Button from "@material-ui/core/Button";
import PublicIcon from "@material-ui/icons/Public";
import React from "react";
import styled from "styled-components";
import { useSnackbar } from "../../../../app/contexts/snackbar.context";
import { useStudyPlan } from "../../../../app/contexts/study.context";

const BottomControls = ({ errors }) => {
  const history = useHistory();
  const { id, planId } = useParams();
  const {
    runSimulation,
    studyPlan,
    validateSequentialCohort,
    validateSeasonalVariations,
    isSequential,
  } = useStudyPlan();
  const { scenarioStatus } = studyPlan;
  const { showSnackbar } = useSnackbar();

  const handleBack = () =>
    history.push(`/ctpo/study-plans/${id}/${planId}/countries-and-site-groups`);

  const handleRunSimulation = () => {
    if (scenarioStatus === "Draft") {
      const seqValid = isSequential
        ? validateSequentialCohort(history.location.pathname)
        : true;
      const seasonalValid = validateSeasonalVariations();
      if (
        Object.keys(errors || {}).length !== 0 ||
        !seqValid ||
        !seasonalValid
      ) {
        showSnackbar(
          <ul>
            {Object.keys(errors || {}).map((key) => {
              if (key !== "BUTTON_TYPE") return <li>{errors[key]}</li>;
              return "";
            })}
            {!seqValid && <li>Sequential cohorts setup is not valid</li>}
            {!seasonalValid && (
              <li>Country enrollment seasonal variations are not valid</li>
            )}
          </ul>,
          "error"
        );
        return;
      }
      runSimulation();
    }
    history.push(`/ctpo/study-plans/${id}/${planId}/simulation`);
  };

  return (
    <div>
      <Container>
        <Button
          color="primary"
          onClick={() =>
            document.getElementById("country-tabs").scrollIntoView()
          }
        >
          <PublicIcon />
          View Different Country
        </Button>
      </Container>
      <Container>
        <Button variant="outlined" onClick={handleBack}>
          Back
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRunSimulation}
        >
          {scenarioStatus === "Draft"
            ? "Finish & Run Simulation"
            : "Finish & View Simulation"}
        </Button>
      </Container>
    </div>
  );
};

export default BottomControls;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
  gap: 1em;
`;
